<template>
  <v-container fluid>
    <div class="dashboard-page">
      <h1 class="page-title mt-10 mb-6">Dashboard</h1>
      <v-row v-show="dashboard1">
        <v-col lg="4" sm="6" cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="col-12" lg="4">
                  <v-icon size="80">mdi-chart-line</v-icon>
                </v-col>
                <v-col cols="col-12" lg="8">
                    <h6>{{ totalsimpanan }}&nbsp;<v-icon color="textColor">mdi-download</v-icon></h6>
                    <h5>Total Simpanan & Deposito</h5>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-col cols="6">
                <v-btn @click="data_simpanan"
                  color="primary"
                  >Detail</v-btn
                >
              </v-col>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col lg="4" sm="6" cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="col-12" lg="4">
                  <v-icon size="80">mdi-chart-pie</v-icon>
                </v-col>
                <v-col cols="col-12" lg="8">
                    <h6>{{ sisapinjaman }}&nbsp;<v-icon color="textColor">mdi-download</v-icon></h6>
                    <h5>Sisa Pinjaman</h5>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-col cols="6">
                <v-btn @click="daftarpinjaman"
                  color="primary"
                  >Detail</v-btn
                >
              </v-col>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col lg="4" sm="6" cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="col-12" lg="4">
                  <v-icon size="80">mdi-cash</v-icon>
                </v-col>
                <v-col cols="col-12" lg="8">
                    <h6>{{totaltagihan}}&nbsp;<v-icon color="textColor">mdi-download</v-icon></h6>
                    <h5>Tagihan Bulan Ini</h5>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-col cols="6">
                <v-btn @click="daftartagihan"
                  color="primary"
                  >Detail</v-btn
                >
              </v-col>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-show="dashboard2">
        <v-col lg="4" sm="6" cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="col-12" lg="4">
                  <v-icon size="80">mdi-chart-line</v-icon>
                </v-col>
                <v-col cols="col-12" lg="8">
                    <h6>{{ pemasukansimpanan }}&nbsp;<v-icon color="textColor">mdi-download</v-icon></h6>
                    <h5> Simpanan</h5>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-col cols="6">
                <v-btn
                  color="primary"
                  @click="detail_simpanan"
                  >Detail</v-btn
                >
              </v-col>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col lg="4" sm="6" cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="col-12" lg="4">
                  <v-icon size="80">mdi-chart-pie</v-icon>
                </v-col>
                <v-col cols="col-12" lg="8">
                    <h6>{{ pemasukankredit }}&nbsp;<v-icon color="textColor">mdi-download</v-icon></h6>
                    <h5>Pemasukan Kredit</h5>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-col cols="6">
                <v-btn
                  color="primary"
                  @click="detail_kredit"
                  >Detail</v-btn
                >
              </v-col>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col lg="4" sm="6" cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="col-12" lg="4">
                  <v-icon size="80">mdi-account</v-icon>
                </v-col>
                <v-col cols="col-12" lg="8">
                    <h6>{{ kreditjatuhtempo }}&nbsp;<v-icon color="textColor">mdi-download</v-icon></h6>
                    <h5>Kredit Jatuh Tempo</h5>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-col cols="6">
                <v-btn
                  color="primary"
                  >Detail</v-btn
                >
              </v-col>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

       <v-row v-show="dashboard3">
        <v-col lg="4" sm="6" cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="col-12" lg="4">
                  <v-icon size="80">mdi-chart-line</v-icon>
                </v-col>
                <v-col cols="col-12" lg="8">
                    <h6>{{ totalsimpanan }}&nbsp;<v-icon color="textColor">mdi-download</v-icon></h6>
                    <h6> Simpanan & Deposito</h6>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-col cols="6">
                <v-btn
                  color="primary"
                  @click="datasimpananadmin"
                  >Detail</v-btn
                >
              </v-col>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col lg="4" sm="6" cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="col-12" lg="4">
                  <v-icon size="80">mdi-chart-pie</v-icon>
                </v-col>
                <v-col cols="col-12" lg="8">
                    <h6>{{ sisapinjaman }}&nbsp;<v-icon color="textColor">mdi-download</v-icon></h6>
                    <h6>Total Kredit</h6>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-col cols="6">
                <v-btn
                  color="primary"
                  @click="datakredit"
                  >Detail</v-btn
                >
              </v-col>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col lg="4" sm="6" cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="col-12" lg="4">
                  <v-icon size="80">mdi-account</v-icon>
                </v-col>
                <v-col cols="col-12" lg="8">
                    <h6>{{   Number(parseFloat( pemasukankredit) + parseFloat (pemasukansimpanan)).toLocaleString() }}&nbsp;<v-icon color="textColor">mdi-download</v-icon></h6>
                    <h6>Transaksi Harian</h6>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-col cols="6">
                <v-btn
                  color="primary"
                  @click="transaksi"
                  >Detail</v-btn
                >
              </v-col>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              
              <div class="text-xl-h4">News</div>
              <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="news"
              :search="search"
            ></v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

export default {
  name: 'Dashboard',
  data () {
    return {
      dashboard1: false,
      dashboard2: false,
      dashboard3: false,
      totalsimpanan : 0,
      totaltagihan:0,
      sisapinjaman : 0,
      pemasukansimpanan : 0,
      pemasukankredit : 0,
      kreditjatuhtempo : 0,
      search: '',
      headers: [
        { text: 'Judul', value: 'judul' },
        { text: 'Detail', value: 'detail' },
        { text: 'Gambar', value: 'gambar' },
        { text: 'Tanggal', value: 'tanggal' },
      ],
      news: [],
    }
  }, 
  computed: {
    ...mapGetters({
      guest : 'auth/guest',
      user : 'auth/user',
    }),
  },
  mounted: function() {
    let now = new Date()

    let formData = {
      'id' : this.user.pk,
      'tgl' : moment(now).format("YYYY-MM-DD"),
    }

    if (this.user.roles === "ADMIN"){
      let formData = {
      'id' : '',
      'tgl' : moment(now).format("YYYY-MM-DD"),
      }
       this.axios.post('/getTotalTabungandt', formData)
        .then((response) => {
          let { data } = response.data
          this.pemasukansimpanan = data
          if (this.pemasukansimpanan === null) {
            this.pemasukansimpanan=0
          } 
        })
        .catch((error) => {
          let responses = error.response
          console.log(responses.data.message)
      })
      this.axios.post('/getTotalPembayaranKredit', formData)
        .then((response) => {
          let { data } = response.data
          this.pemasukankredit = data
          if (this.pemasukankredit=== null) {
            this.pemasukankredit = 0
          }
        })
        .catch((error) => {
          let responses = error.response
          console.log(responses.data.message)
      })

      this.axios.post('/totalSimpanan', formData)
        .then((response) => {
          let { data } = response.data
          this.totalsimpanan = Number(data).toLocaleString();
        })
        .catch((error) => {
          let responses = error.response
          console.log(responses.data.message)
        })

        this.axios.post('/sisaPinjaman', formData)
          .then((response) => {
            let { data } = response.data
            this.sisapinjaman = Number(data).toLocaleString();
          })
          .catch((error) => {
            let responses = error.response
            console.log(responses.data.message)
          })

    }

    if (this.user.roles === "KOLEKTOR"){
      let formData = {
        'id' : this.user.pk,
      'tgl' : moment(now).format("YYYY-MM-DD"),
      }
         this.axios.post('/getTotalTabungandt', formData)
        .then((response) => {
          let { data } = response.data
          this.pemasukansimpanan = Number(data).toLocaleString();
        })
        .catch((error) => {
          let responses = error.response
          console.log(responses.data.message)
        })

        this.axios.post('/getTotalPembayaranKredit', formData)
        .then((response) => {
          let { data } = response.data
          this.pemasukankredit = Number(data).toLocaleString();
        })
        .catch((error) => {
          let responses = error.response
          console.log(responses.data.message)
        })
        this.axios.post('/getKreditJatuhTempo', formData)
        .then((response) => { 
          let { data } = response.data
          this.kreditjatuhtempo = Number(data).toLocaleString();
        })
        .catch((error) => {
          let responses = error.response
          console.log(responses.data.message)
        })
        
    }

    if (this.user.roles === "NASABAH"){
          this.axios.post('/totalSimpanan', formData)
          .then((response) => {
            let { data } = response.data
            this.totalsimpanan = Number(data).toLocaleString();
          })
          .catch((error) => {
            let responses = error.response
            console.log(responses.data.message)
          })
          
          this.axios.post('/sisaPinjaman', formData)
          .then((response) => {
            let { data } = response.data
            this.sisapinjaman = Number(data).toLocaleString();
          })
          .catch((error) => {
            let responses = error.response
            console.log(responses.data.message)
          })

          this.axios.post('/totalTagihan', formData)
          .then((response) => {
            let { data } = response.data
            this.totaltagihan = Number(data).toLocaleString();
          })
          .catch((error) => {
            let responses = error.response
            console.log(responses.data.message)
          })

    }

    this.axios.post('/listNews')
    .then((response) => {
      let { data } = response.data
      this.news = data;
    })
    .catch((error) => {
      let responses = error.response
      console.log(responses.data.message)
    })
    

    if(this.user.roles === "NASABAH"){
      this.dashboard1 = true
    }
    if(this.user.roles === "KOLEKTOR"){
      this.dashboard2 = true
    }
     if(this.user.roles === "ADMIN"){
      this.dashboard3 = true
    }
  },
  methods: {
    ...mapActions({
        setAuth   : 'auth/set',
        setAlert : 'alert/set',
    }),
    
    transaksi(){
       this.$router.push('transaksiall');
    },
    detail_simpanan(){
      // this.$router.push('transaksisimpanan');
      this.$router.push('transaksiharian');
    },
    detail_kredit(){
      // this.$router.push('setorankreditur');
      this.$router.push('transaksiharianpinjaman');
    },
    // administrator
    datasimpananadmin(){
      // this.$router.push('transaksisimpanan');
      this.$router.push('datasimpananadmin');
    },

    datakredit(){
      // this.$router.push('setorankreditur');
      this.$router.push('datakredit');
    },


    data_simpanan(){
      this.$router.push('/datasimpanan');
    },

    daftarpinjaman(){
      this.$router.push('/daftarpinjaman');
    },

    daftartagihan(){
      this.$router.push('/daftartagihan');
    },


  },
}

</script>

<style src="./Dashboard.scss" lang="scss"/>
