<template>
  <v-container fluid>
    <h1 class="page-title mt-10 mb-6">Setoran Kreditur</h1>
    <v-row>
        <v-col md=12>
            <v-card class="mb-1">
                <v-card-text>
                    <v-form ref="form" lazy-validation> 
                        <v-row>
                            <v-col cols="col-12" lg="6">
                                <v-combobox
                                    v-model="selNasabah"
                                    item-text="pinjaman"
                                    item-value="no_pinjaman"
                                    :items="itemNasabah"
                                    :search-input.sync="search"
                                    @change="selectedOption"
                                    label="Nasabah"
                                    prepend-icon="mdi-magnify"
                                    outlined
                                    dense>
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                No results matching "<strong>{{ search }}</strong>"
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-combobox>
                                
                                <div v-show="formShowD">
                                    <v-col>
                                        <v-row>
                                            <v-col cols="col-12" lg="6">
                                                 <v-text-field disabled
                                                    v-model="txtJenisAngsuran"
                                                    label="Periode Angsuran">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="col-12" lg="6">
                                                  <v-text-field disabled
                                                    v-model="txtDAngsuran"
                                                    label="Angsuran ke">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>



                                         <v-row>
                                            <v-col cols="col-12" lg="6">
                                                  <v-text-field  disabled
                                                    v-model="txtDSisaPinjaman"
                                                    label="Sisa Pinjaman">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="col-12" lg="6">
                                                  <v-text-field disabled
                                                    v-model="txtpokokcicilan"
                                                    label="Pokok Cicilan">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col cols="col-12" lg="6">    
                                                <v-text-field v-model="txtTerlambat" disabled
                                                    label="Terlambat (hari)">
                                                </v-text-field>
                                                
                                            </v-col>
                                            <v-col cols="col-12" lg="6">
                                                <v-text-field disabled v-model="txtbayar" 
                                                    label="Jumlah Harus Dibayarkan">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-text-field 
                                            v-model="txtDBayar"
                                            label="Bayar">
                                        </v-text-field>
                                        <v-text-field  disabled
                                            v-model="txtDSaldoAkhir"
                                            label="Saldo Akhir">
                                        </v-text-field>
                                    </v-col>
                                </div>
                                <div v-show="formShowB">
                                    <v-col>
                                        <v-row>
                                            <v-col cols="col-12" lg="6">
                                                <v-text-field 
                                                    v-model.number="txtBTotalPinjam"
                                                    label="Total Pinjam">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="col-12" lg="6">
                                                <v-text-field 
                                                    v-model="txtBAngsuran"
                                                    label="Angsuran ke">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                         <v-row>
                                             <v-col cols="col-12" lg="6">
                                                 <v-text-field 
                                                    v-model="txtpokokcicilan"
                                                    label="Cicilan per Bulan">
                                                </v-text-field>
                                             </v-col>
                                             <v-col cols="col-12" lg="6">
                                                  <v-text-field v-model="txtTerlambat" disabled
                                                        label="Terlambat (bulan)">
                                                    </v-text-field>
                                             </v-col>
                                         </v-row>
                                        

                                        <v-text-field 
                                            v-model="txtBPokok"
                                            label="Pokok">
                                        </v-text-field>

                                        
                                        <v-text-field 
                                            v-model="txtBBunga"
                                            label="Bunga">
                                        </v-text-field>
                                        <v-text-field 
                                            v-model="txtBDenda"
                                            label="Denda">
                                        </v-text-field>
                                        <v-text-field 
                                            v-model="txtBTotalBayar"
                                            label="Total Bayar">
                                        </v-text-field>
                                    </v-col>
                                </div>

                                <input type="hidden" name="periodepinjam" class="form-control" :value="periodepinjam">
                                <input type="hidden" name="nopinjaman" class="form-control" :value="nopinjaman">
                                <input type="hidden" name="nasabahfk" class="form-control" :value="nasabahfk">
                                
                                <v-col v-show="btnShow">
                                    <v-btn
                                        color="success"
                                        class="text-capitalize button-shadow mr-1"
                                        @click="simpan"
                                        >Simpan</v-btn>
                                    <v-btn
                                        color="success"
                                        class="text-capitalize button-shadow mr-1"
                                        @click="cetak"
                                        >Cetak</v-btn>
                                </v-col>
                            </v-col>
                            <v-col cols="col-12" v-show="printShow">                                
                                <div id="print">
                                    <v-col>
                                        <table>
                                            <tr>
                                                <td colspan="3">
                                                    <center>Bukti Setoran</center> <br>
                                                    <center>KSP PUTRA DARMA CITRA</center>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Tanggal
                                                </td>
                                                <td style='width: 30px;' >
                                                    :
                                                </td>
                                                <td>
                                                    {{ this.nowDate }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    No Pinjaman
                                                </td>
                                                <td style='width: 30px;' >
                                                    :
                                                </td>
                                                <td>
                                                    {{ this.selNasabah.no_pinjaman }}
                                                </td>
                                            </tr>                                            
                                            <tr>
                                                <td>
                                                    Nama Nasabah
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                                <td>
                                                    {{ this.selNasabah.nama_nasabah }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="3">
                                                    ============================================
                                                </td>
                                            </tr>
                                            <div v-if="this.periodepinjam === 'Bulanan'">
                                                <tr>
                                                    <td>
                                                        Total Pinjaman
                                                    </td>
                                                    <td>
                                                        :
                                                    </td>
                                                    <td style="text-align:right">
                                                        {{ this.txtBTotalPinjam.toLocaleString() }}
                                                    </td>
                                                </tr>         
                                                <tr>
                                                    <td>
                                                        Angsuran ke-
                                                    </td>
                                                    <td>
                                                        :
                                                    </td>
                                                    <td style="text-align:right">
                                                        {{ this.txtBAngsuran }}
                                                    </td>
                                                </tr>  
                                                <tr>
                                                    <td>
                                                        Pokok
                                                    </td>
                                                    <td>
                                                        :
                                                    </td>
                                                    <td style="text-align:right">
                                                        {{ parseInt(this.txtBPokok).toLocaleString() }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Bunga
                                                    </td>
                                                    <td>
                                                        :
                                                    </td>
                                                    <td style="text-align:right">
                                                        {{ parseInt(this.txtBBunga).toLocaleString() }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Denda
                                                    </td>
                                                    <td>
                                                        :
                                                    </td>
                                                    <td style="text-align:right">
                                                        {{ parseInt(this.txtBDenda).toLocaleString() }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Total Bayar
                                                    </td>
                                                    <td>
                                                        :
                                                    </td>
                                                    <td style="text-align:right">
                                                        {{ parseInt(this.txtBTotalBayar).toLocaleString() }}
                                                    </td>
                                                </tr>
                                            </div>
                                            <div v-if="this.periodepinjam === 'Harian'">   
                                                <tr>
                                                    <td>
                                                        Angsuran ke-
                                                    </td>
                                                    <td>
                                                        :
                                                    </td>
                                                    <td>
                                                        {{ this.txtBAngsuran }}
                                                    </td>
                                                </tr>  
                                                <tr>
                                                    <td>
                                                        Sisa Pinjaman
                                                    </td>
                                                    <td>
                                                        :
                                                    </td>
                                                    <td style="text-align:right">
                                                        {{ this.txtDSisaPinjaman.toLocaleString() }}
                                                    </td>
                                                </tr>

                                               

                                                <tr>
                                                    <td>
                                                        Total Bayar
                                                    </td>
                                                    <td>
                                                        :
                                                    </td>
                                                    <td style="text-align:right">
                                                        {{ parseInt(this.txtDBayar).toLocaleString() }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Saldo Akhir
                                                    </td>
                                                    <td>
                                                        :
                                                    </td>
                                                    <td style="text-align:right">
                                                        {{ parseInt(this.txtDSaldoAkhir).toLocaleString() }}
                                                    </td>
                                                </tr>
                                            </div>
                                            <tr>
                                                <td colspan="3">
                                                    ============================================
                                                </td>
                                            </tr>
                                            <tr>
                                                
                                                <td style="text-align:center">
                                                Kolektor
                                                <br>
                                                <br>
                                                {{username}}
                                                </td>

                                                
                                                <td>
                                                </td>
                                                <td style="text-align:center">
                                                    Nasabah
                                                    <br>
                                                    <br>
                                                    -------------
                                                </td>
                                            </tr>
                                        </table>
                                    </v-col>
                                </div>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <div id="print" class="tables-basic" v-show="tableShow">
      <v-row>
        <v-col cols=12>
          <v-card class="mb-1">  
              <v-card-title class="subheading font-weight-bold justify-center">
                <center>
                    <h2>Transaksi Simpanan</h2>
                    <h6>{{ selNasabah.pinjaman }}</h6>
                </center>
              </v-card-title>
            <v-simple-table>
                <thead>
                    <tr>
                        <th class="text-center aksi">Cicilan ke</th>
                        <th class="text-center">No Bayar</th>
                        <th class="text-center">Nama</th>
                        <th class="text-center">Jenis</th>
                        <th class="text-center">Sistem Bunga</th>
                        <th class="text-right">Pokok</th>
                        <th class="text-right">Bunga</th>
                        <th class="text-right">Denda</th>
                        <th class="text-right">Total Bayar</th>
                        <th class="text-right">Saldo Akhir</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="data in dataTablePembayaran" :key="data.pk_bayar">
                        <!-- <td class="aksi">
                            <v-btn
                                color="danger"
                                class="text-capitalize button-shadow mr-1"
                                @click="hapus"
                                >hapus</v-btn>
                        </td> -->
                        <td>{{ data.angsuran }}</td>
                        <td>{{ data.notrs_bayar }}</td>
                        <td>{{ data.nama_nasabah }}</td>
                        <td>{{ data.periode_pinjam }}</td>
                        <td>{{ data.sistem_bunga=="1" ? "Menurun" : "Tetap" }}</td>
                        <td class="text-right">{{ Number(data.pokok).toLocaleString() }}</td>
                        <td td class="text-right">{{ Number(data.bunga).toLocaleString() }}</td>
                        <td td class="text-right">{{ Number(data.denda).toLocaleString() }}</td>
                        <td td class="text-right">{{ Number(data.total_bayar).toLocaleString() }}</td>
                        <td td class="text-right">{{ Number(data.saldo_akhir).toLocaleString() }}</td>

                    </tr>
                </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

export default {
  name: 'SetoranKreditur',
  data () {
    return {
        selNasabah: "",
        itemNasabah: [],
        search: null,
        formShowB: false,
        formShowD: false,
        btnShow: false,
        tableShow: false,
        printShow: false,
        dataTablePembayaran : [],
        /** hidden */
        periodepinjam: "",
        nopinjaman: "",
        nasabahfk: "",
        /** input */
        txtDAngsuran: 0,
        txtDSisaPinjaman: 0,
        txtDBayar: 0,
        txtDSaldoAkhir: 0,
        txtbayar:0,
        txtBTotalPinjam: 0,
        txtBAngsuran: 0,
        txtBPokok: 0,
        txtBBunga: 0,
        txtBDenda: 0,
        txtBSisaPinjaman: 0,
        txtBTotalBayar: 0,
        txtTerlambat:0,
        txtsaldoawal:0,
        angsuranke : 0,
        nowDate: moment(new Date()).format("YYYY-MM-DD"),
        username:'',
        txtJenisAngsuran:'',
        txtpokokcicilan:0,
        settingpinjaman : [],
        dendatetap:0,
        dendamenurun :0,
        akundenda:'',
        akunkas:'',
        akunrealisasi:'',
        akunbunga:'',
        tenggangdenda:0,
        sisa:0,
    }
  }, 
    watch: {
        search:function(){
            this.searchNasabah()
        },
        txtDBayar:function(){
            this.hitungsaldoAkhirHarian()
        },
        txtBTotalBayar:function(){
            this.hitungsaldoAkhirBulanan()
        },
        txtBPokok:function(){
            this.hitungsaldobayar()
        },
        txtBBunga:function(){
            this.hitungsaldobayar()
        },
        txtBDenda:function(){
            this.hitungsaldobayar()
        },



    },
    computed: {
        ...mapGetters({
            user : 'auth/user',
        }),
         

    },
    mounted: function(){
        this.username=this.user.username

         this.axios.post('/getSettingPinjaman')
        .then((response) => {
            let { data } = response.data;
            this.settingpinjaman=data;
             this.dendatetap = this.settingpinjaman[0].dendatetap;
            
            this.dendamenurun = this.settingpinjaman[0].bungamenurun;
            // this.akundenda=this.settingpinjaman[0].akundenda;
            this.tenggangdenda=this.settingpinjaman[0].tenggangdenda;
            // this.akunkas=this.settingpinjaman[0].akunkas;
            // this.akunrealisasi=this.settingpinjaman[0].akunrealisasi;


            
            // console.log('denda', this.dendatetap);
        })
        .catch((error) => {
            let responses = error.response
            console.log(responses.data.message)
        })
        
    },
  
  methods: {
    ...mapActions({
        setAlert : 'alert/set',
        setAuth : 'auth/set',
    }),
    hitungsaldobayar(){
        this.txtBTotalBayar  = parseFloat(this.txtBBunga) + parseFloat(this.txtBPokok)+ parseFloat(this.txtBDenda)
        this.sisa =  parseFloat(this.txtBSisaPinjaman) -parseFloat(this.txtBPokok) 
    },
    hitungsaldoAkhirBulanan(){
          this.txtBPokok  = parseFloat(this.txtBTotalBayar) - parseFloat(this.txtBBunga)- parseFloat(this.txtBDenda)
          this.sisa =  parseFloat(this.txtBSisaPinjaman) -parseFloat(this.txtBPokok) 
    },
    hitungsaldoAkhirHarian(){
        // this.txtbayar=this.txtDBayar
        this.txtDSaldoAkhir  = parseFloat(this.txtsaldoawal) - parseFloat(this.txtDBayar)
        // this.txtDSaldoAkhir =this.txtDSaldoAkhir.toLocaleString()
    },
    searchNasabah(){
        let keyword = this.search
        if(keyword.length>0){
            this.axios.get('/searchNasabahPinjaman/'+keyword)
            .then((response) => {
                let { data } = response.data
                this.itemNasabah = data;
            })
            .catch((error) => {
                console.log(error)
            })
        }else{
            this.itemNasabah = []
        }
    },
    selectedOption(){
        let keyword = this.selNasabah.no_pinjaman
        if(keyword.length>0){
            this.axios.get('/getPinjaman/'+keyword)
            .then((response) => {
                let { data } = response.data
                this.itemNasabah = data;

                let formData = new FormData()
                formData.set('nopinjaman', this.itemNasabah[0].notrs)

                this.axios.post('/getDataTablePembayaran', formData)
                .then((response) => {
                    let { data } = response.data
                    this.dataTablePembayaran = data
                    this.txtBAngsuran = parseInt(this.dataTablePembayaran.length)+1
                    this.txtDAngsuran = parseInt(this.dataTablePembayaran.length)+1
                    this.txtDSaldoAkhir = this.dataTablePembayaran[this.dataTablePembayaran.length-1].saldo_akhir
                    this.tableShow = true
                })
                .catch((error) => {
                     let responses = error.response
                    this.setAlert({
                    status : true,
                    color : 'error',
                      text : responses.data.message,
                      
                    })
                })

                if(this.itemNasabah[0].periodepinjam === "Harian"){
                    //  this.txtDSisaPinjaman = this.itemNasabah[0].sisapinjaman.toLocaleString()
                    this.txtDSisaPinjaman = this.itemNasabah[0].sisapinjaman.toLocaleString()
                    this.txtBTotalPinjam = this.itemNasabah[0].totalpeminjaman 
                    this.txtsaldoawal = this.itemNasabah[0].sisapinjaman
                    let startdate=this.nowDate
                    if (this.itemNasabah[0].tglbayarterakhir){
                          startdate=this.itemNasabah[0].tglbayarterakhir
                    }else{
                          startdate=this.itemNasabah[0].tanggalpersetujuan
                    }
                    let enddate=this.nowDate

                    this.txtTerlambat=moment(enddate).diff(moment(startdate),'days')
                    // this.txtDBayar = parseFloat(this.txtTerlambat) * parseFloat(this.itemNasabah[0].jumlahangsuran)
                    this.txtpokokcicilan=this.itemNasabah[0].jumlahangsuran.toLocaleString()
                    this.txtbayar = parseFloat(this.txtTerlambat) * parseFloat(this.itemNasabah[0].jumlahangsuran)
                    this.txtbayar=this.txtbayar.toLocaleString()
                    // this.txtDSaldoAkhir = parseFloat(this.txtsaldoawal)- parseFloat( this.txtDBayar)
                    this.hitungsaldoAkhirHarian()
                    
                    this.periodepinjam = this.itemNasabah[0].periodepinjam
                    this.nopinjaman = this.itemNasabah[0].notrs
                    this.nasabahfk =this.itemNasabah[0].nasabahfk
                    this.txtJenisAngsuran="Harian"

                    // this.akunkas = this.settingpinjaman[0].akunkasharian;                    
                    this.akunkas = this.itemNasabah[0].akunkas;                    
                    this.akundenda='';
                    this.akunbunga='';
                    this.akunrealisasi=this.settingpinjaman[0].akunpinjamharian;

                    this.formShowB = false
                    this.formShowD = true
                    this.btnShow = true
                }

                if(this.itemNasabah[0].periodepinjam === "Bulanan"){
                    this.txtBSisaPinjaman = this.itemNasabah[0].sisapinjaman
                    this.txtBTotalPinjam = this.itemNasabah[0].totalpeminjaman 
                    this.txtJenisAngsuran="Bulanan"
                    let startdate=this.nowDate
                    if (this.itemNasabah[0].tglbayarterakhir){
                          startdate=this.itemNasabah[0].tglbayarterakhir
                    }else{
                          startdate=this.itemNasabah[0].tanggalpersetujuan
                    }
                     
                    
                    let enddate=this.nowDate
                     
                    this.txtTerlambat=Number(moment(enddate).diff(moment(startdate),'months',true)).toFixed()

                   

                    this.txtpokokcicilan=0
                    // bunga tetap
                    if (this.itemNasabah[0].sistempinjam === "0")  {
                        this.txtpokokcicilan=this.itemNasabah[0].jumlahangsuran
                        this.txtBPokok = Number(parseFloat(this.itemNasabah[0].totalpeminjaman)/parseFloat(this.itemNasabah[0].lamapinjam)).toFixed()
                        this.txtBBunga = parseFloat(this.txtpokokcicilan)-parseFloat(this.txtBPokok)
                    }else{
                        this.txtpokokcicilan=Number((parseFloat(this.itemNasabah[0].totalpeminjaman)/parseFloat(this.itemNasabah[0].lamapinjam)) + (parseFloat(this.itemNasabah[0].sisapinjaman) * parseFloat(this.itemNasabah[0].bungapinjam) /1200)).toFixed()
                        this.txtBPokok = Number(parseFloat(this.itemNasabah[0].totalpeminjaman)/parseFloat(this.itemNasabah[0].lamapinjam)).toFixed()
                        this.txtBBunga = Number(parseFloat(this.itemNasabah[0].sisapinjaman) * parseFloat(this.itemNasabah[0].bungapinjam) /1200).toFixed()
                        if (this.txtBPokok > this.itemNasabah[0].sisapinjaman){
                            this.txtBPokok =this.itemNasabah[0].sisapinjaman
                        } 
                    }
                    
                     
                    if ((parseInt(this.txtTerlambat) > parseInt(this.tenggangdenda))  ){
                        this.txtBDenda =(parseFloat(this.txtTerlambat)-parseInt(this.tenggangdenda))  * parseFloat(this.dendatetap) * parseFloat(this.txtBPokok)/100
                        
                    }else{
                        this.txtBDenda = 0
                    }

                    // if ((parseInt(this.txtTerlambat)-parseInt(this.tenggangdenda)) > 1 ){
                    //         this.txtBDenda =(parseFloat(this.txtTerlambat)-parseInt(this.tenggangdenda))  * parseFloat(this.dendatetap) * parseFloat(this.txtBPokok)/100
                    // }else{
                    //     this.txtBDenda = 0
                    // }
                    
                    this.txtBTotalBayar =   parseFloat(this.txtBDenda) + parseFloat(this.txtBPokok) + parseFloat(this.txtBBunga)
                // console.log ('txtBTotalBayar', this.txtBTotalBayar)
                    this.periodepinjam = this.itemNasabah[0].periodepinjam
                    this.nopinjaman = this.itemNasabah[0].notrs
                    this.nasabahfk =this.itemNasabah[0].nasabahfk

                    // this.akunkas = this.settingpinjaman[0].akunkas;      
                    this.akunkas = this.itemNasabah[0].akunkas;                                  
                    this.akundenda=this.settingpinjaman[0].akundenda;
                    this.akunbunga=this.settingpinjaman[0].akunbunga;
                    this.akunrealisasi=this.settingpinjaman[0].akunrealisasi;
                    this.formShowD = false
                    this.formShowB = true
                    this.btnShow = true
                }
            })
            .catch((error) => {
                console.log(error)
            })
        }else{
            this.itemNasabah = []
        }
    },
    simpan(){
        let now = new Date()
        let formData = new FormData()

        if(this.periodepinjam === "Harian"){
            formData.set('notrs', 'BP'+ moment(now).format("YYMMDDHHmmss"))
            formData.set('tgl', moment(now).format("YYYY-MM-DD HH:mm:ss"))
            formData.set('no_pinjaman', this.nopinjaman)
            formData.set('saldoawal', this.txtBTotalPinjam)
            formData.set('nasabahfk', this.nasabahfk)
            formData.set('angsuranke', this.txtDAngsuran)
            formData.set('pokok', this.txtDBayar)
            formData.set('bunga', 0)
            formData.set('denda', 0)
            formData.set('akundenda', this.akundenda)
            formData.set('akunbunga', this.akunbunga)
            formData.set('akunrealisasi', this.akunrealisasi)
            formData.set('akunkas', this.akunkas)
            formData.set('totalbayar', this.txtDBayar)
            formData.set('saldoakhir', this.txtDSaldoAkhir)
            formData.set('marketingfk', this.user.pk)
            formData.set('periodepinjam', "Harian")
            formData.set('ket', "Bayar Kredit: " + this.nopinjaman)
             
        }

        if(this.periodepinjam === "Bulanan"){
            this.sisa =  parseFloat(this.txtBSisaPinjaman) -parseFloat(this.txtBPokok)
            
            formData.set('notrs', 'BP'+ moment(now).format("YYMMDDHHmmss"))
            formData.set('tgl', moment(now).format("YYYY-MM-DD HH:mm:ss"))
            formData.set('no_pinjaman', this.nopinjaman)
            formData.set('saldoawal', this.txtBTotalPinjam)
            formData.set('nasabahfk', this.nasabahfk)
            formData.set('angsuranke', this.txtBAngsuran)
            formData.set('pokok', this.txtBPokok)
            formData.set('bunga', this.txtBBunga)
            
            formData.set('akundenda', this.akundenda)
            formData.set('akundenda', this.akundenda)
            formData.set('akunbunga', this.akunbunga)
            formData.set('akunrealisasi', this.akunrealisasi)
            formData.set('akunkas', this.akunkas)

            formData.set('denda', this.txtBDenda)
            formData.set('totalbayar', this.txtBTotalBayar)
            formData.set('saldoakhir', this.sisa)
            formData.set('marketingfk', this.user.pk)
            formData.set('periodepinjam', "Bulanan")
            formData.set('ket', 'Bayar Kredit: ' + this.nopinjaman)
        }

        this.axios.post('/insertPembayaran', formData)
        .then((response) => {
            if(response.data.status == "success"){
                this.setAlert({
                    status : true,
                    color : 'success',
                    text : response.data.message,
                })
            }
            
            this.cetak()
            this.selectedOption()
        })
        .catch((error) => {
            let responses = error.response
            this.setAlert({
            status : true,
            color : 'error',
            text : responses.data.message,
            })
        })
    },
    cetak(){
        let divToPrint = document.getElementById('print');
        let htmlToPrint = '' +
                '<style type="text/css">' +
                '@media print { ' +
                '@page {' +
                    'size: statement;' +
                '}'+
                'html, body {' +
                    'width: 58mm;' +
                '}'+
                '}' +
                '</style>';
        htmlToPrint += divToPrint.outerHTML;
        let newWin = window.open("");
        newWin.document.write(htmlToPrint);
        newWin.print();
        newWin.close();
    },
    hapus(){},
  },
}

</script>
